@use "sass:map";

@use "../../design-tokens/typography" as typography;
@use "../../design-tokens/colors" as colors;
@use "../../design-tokens/mixins/glow" as glow;

:root {
  --pb-tabs-tab-active-color: #{map.get(colors.$colors, "blue-700")};
  --pb-tabs-tab-color: #{map.get(colors.$colors, "grey-600")};
  --pb-tabs-tab-hover-background: #{map.get(colors.$colors, "grey-200")};
  --pb-tabs-tab-hover-color: #{map.get(colors.$colors, "grey-600")};
  --pb-tabs-tab-disabled-color: #{map.get(colors.$colors, "grey-400")};
  --pb-tabs-tab-focus-color: #{map.get(colors.$colors, "grey-800")};
}

.p-tabs {  
  --p-tabs-tablist-background: transparent;
  
  --p-tabs-tab-padding: 1.25rem 2rem;
  
  --p-tabs-tab-color: var(--pb-tabs-tab-color);
  
  --p-tabs-tab-hover-background: var(--pb-tabs-tab-hover-background);
  --p-tabs-tab-hover-color: var(--pb-tabs-tab-hover-color);
  --p-tabs-tab-hover-border-color: transparent;
  
  --p-tabs-tab-active-color: var(--pb-tabs-tab-active-color);
  --p-tabs-tab-active-border-color: var(--pb-tabs-border-color);
  
  .p-tablist-tab-list {
    border: none;
    background: var(--p-tabs-tablist-background);
  }
  
  .p-tab {
    border: none;
    border-bottom: 0.125rem solid transparent;
    @include typography.pb-body-medium-compact;
    
    &.p-disabled {
      cursor: not-allowed;
      color: var(--pb-tabs-tab-disabled-color);
    }
  }
  
  .p-tab:not(.p-disabled):focus-visible {
    box-shadow: none;
    border-color: transparent;
    outline: none;
    color: var(--pb-tabs-tab-focus-color);
    @include glow.glow(map.get(colors.$colors, "blue-800"));
  }
  
  .p-tab-active {
    background: var(--p-tabs-tab-active-background);
    border-color: var(--p-tabs-tab-active-border-color);
    color: var(--p-tabs-tab-active-color);
    cursor: unset;
  }
  
  &.small {
    & .p-tab {
      border-width: 0 0 0.063rem 0;
      --p-tabs-tab-padding: 0.75rem 1.5rem;
    }
  }
}