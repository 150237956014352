/*
 * This file only contains the theming and typography, and where it is used
*/
@use '@angular/material' as mat;
@import 'palette';

@font-face {
  font-family: "Inter";
  src: url('/assets/fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
}


@include mat.elevation-classes();
@include mat.app-background();

// Define custom theme.
$custom-theme-primary: mat.m2-define-palette($iam-blue);
$custom-theme-accent: mat.m2-define-palette($iam-orange);
$custom-theme-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Setup typography
$font-family-base: 'Inter', sans-serif;
$font-size-h1: 1.5rem;
$font-size-h2: 1rem;
$font-size-h3: 1.471em;
$font-size-h4: 1.3em;
$font-size-h5: 1.243em;
$font-size-h6: 1.132em;

$line-height-h1: 2rem;
$line-height-h2: 1.5rem;
$line-height-h3: calc(18px + 0.7vw);
$line-height-h4: calc(18px + 0.2vw);

$font-size-base: 0.95em;
$line-height-base: 1.3em;
$font-weight-base: 400;
$font-weight-bold: 700;

$default-letter-spacing: normal;

$font-size-caption: calc((#{$font-size-base} * (14 / 16)));

$custom-typography: mat.m2-define-typography-config(
  $headline-1:   mat.m2-define-typography-level(
    $font-size-h1,
    $line-height-h1,
    $font-weight-bold, 
    $letter-spacing: $default-letter-spacing,
  ),
  $headline-2:   mat.m2-define-typography-level(
    $font-size-h2,
    $line-height-h2,
    $font-weight-base,
    $letter-spacing: $default-letter-spacing,
  ),
  $headline-5:
    mat.m2-define-typography-level(
      $font-size-h1,
      $line-height-h1,
      $font-weight-bold,
      $letter-spacing: $default-letter-spacing,
    ),
  $body-1: mat.m2-define-typography-level(
    $font-size-base,
    $line-height-base,
    $font-weight-base, 
    $letter-spacing: $default-letter-spacing,
  ),
);

// Create themes
$custom-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $custom-theme-primary,
      accent: $custom-theme-accent,
      warn: $custom-theme-warn,
    ),
    typography: $custom-typography,
    density: 0,
  )
);


@include mat.all-component-typographies($custom-typography);
body,
body .p-component
{
  font-family: $font-family-base;
}
