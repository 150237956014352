// Include non-theme styles for core.
@use '@angular/material' as mat;
@use '@portbase/design-system/primeng-styling/portbase';

@include mat.elevation-classes();
@include mat.app-background();
@include mat.core();
@import 'styles/theme';

// Include theme styles for Angular Material components.
@include mat.all-component-themes($custom-theme);
@include mat.elevation-classes();
@include mat.app-background();

@import 'styles/material_styles';

@import 'styles/global.scss';
@import 'ngx-toastr/toastr';

// Tailwindcss
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// Primeng
@import 'styles/primeng_styles';

@layer base{
  h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    letter-spacing: normal;
    margin-bottom: 16px;
  }

  h2 {
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 400;
    letter-spacing: normal;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 1.471em;
    line-height: calc(18px + 0.7vw);
    font-weight: 400;
    letter-spacing: normal;
    margin-bottom: 16px;
  }

  h4 {
    font-size: 1.3em;
    line-height: calc(18px + 0.5vw);
    font-weight: 400;
    letter-spacing: normal;
    margin-bottom: 16px;
  }

  h5 {
    font-size: 1.243em;
    font-weight: 400;
    letter-spacing: normal;
    margin-bottom: 16px;
  }

  h6 {
    font-size: 1.132em;
    font-weight: 400;
    letter-spacing: normal;
    margin-bottom: 16px;
  }

  p{
    @apply pb-body;
    @apply mb-2;
  }
}

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@layer pb-typography, base, primeng, tailwind-base, tailwind-utilities;
