html,
body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}

@mixin h1 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;
}

@mixin h2 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}

@mixin pb-title {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 400;
}

// 14-20 Regular
@mixin pb-body {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
}

// 14-16 Regular
@mixin pb-body-compact {
  @include pb-body;
  line-height: 1rem;
}

// 14-20 Medium
@mixin pb-body-medium {
  @include pb-body;
  font-weight: 500;
}

// 14-16 Medium
@mixin pb-body-medium-compact {
  @include pb-body-medium;
  line-height: 1rem;
}

// 12-16 Regular
@mixin pb-label {
  @include pb-body;
  font-size: 0.75rem;
  line-height: 1rem;
}

// 12-16 Regular uppercase
@mixin pb-label-uppercase {
  @include pb-label;
  text-transform: uppercase;
}

@layer pb-typography {
  h1 {
    @include h1;
  }

  h2 {
    @include h2;
  }

  .pb-title {
    @include pb-title;
  }

  .pb-body {
    @include pb-body;
  }

  .pb-body-compact {
    @include pb-body-compact;
  }

  .pb-body-medium {
    @include pb-body-medium;
  }

  .pb-body-medium-compact {
    @include pb-body-medium-compact;
  }

  .pb-label {
    @include pb-label;
  }

  .pb-label-uppercase {
    @include pb-label-uppercase;
  }
}
