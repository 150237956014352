@use 'sass:map';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --p-drawer-background: white;
  --p-datatable-header-border-width: 0px;
  --p-datatable-body-cell-border-color: transparent;
  --p-datatable-header-cell-border-color: transparent;

  --p-datepicker-panel-background: white;
  --p-datepicker-panel-border-color: @apply text-pb-grey-800;
  --p-datepicker-panel-color: @apply text-pb-grey-800;
  --p-datepicker-panel-border-radius: 10px;
  --p-datepicker-panel-shadow: 0;
  --p-datepicker-panel-padding: 8px;
  --p-datepicker-header-background: white;
  --p-datepicker-dropdown-focus-ring-shadow: none;

  --p-fileupload-header-border-width: 0px;
  --p-fileupload-header-padding: 1rem;
  
 }


$colors: (
  'grey-100': #f7fafc,
  'grey-200': #eaf0f4,
  'grey-300': #d6e0e7,
  'grey-400': #b7c5ce,
  'grey-500': #8a9da8,
  'grey-600': #566a76,
  'grey-700': #3d5361,
  'grey-800': #243642,
  'grey-900': #151e24,

  'blue-100': #d7e5fb,
  'blue-200': #acc9f5,
  'blue-300': #81adef,
  'blue-400': #5691e9,
  'blue-500': #2b75e3,
  'blue-600': #0059dd,
  'blue-700': #004ab1,
  'blue-800': #003b84,
  'blue-850': #002357,
  'blue-900': #001b42,

  'green-100': #dffed5,
  'green-200': #cbf5bc,
  'green-300': #b4ef9f,
  'green-400': #90d776,
  'green-500': #76d454,
  'green-600': #4ebf25,
  'green-700': #32a905,
  'green-800': #226c06,
  'green-900': #154404,

  'yellow-100': #fff6d1,
  'yellow-200': #ffe88a,
  'yellow-300': #ffe062,
  'yellow-400': #ffda42,
  'yellow-500': #ffd422,
  'yellow-600': #f7c702,
  'yellow-700': #edb403,
  'yellow-800': #997500,
  'yellow-900': #5b4701,

  'red-100': #fde0e0,
  'red-200': #fbc0c0,
  'red-300': #f89595,
  'red-400': #f45252,
  'red-500': #f23636,
  'red-600': #ee0000,
  'red-700': #c40000,
  'red-800': #930000,
  'red-900': #5c0000,
);

/**
 * NGPrime Data table
 */
.p-datatable {
  @apply text-pb-grey-700;

  .p-datatable-thead {
    @apply text-pb-grey-700 text-lg font-medium;

    th {
      @apply font-medium;
      text-align: left;
      height: 4rem;
    }
  }

  .p-datatable-tbody {
    tr {
      height: 3rem;
      font-size: 0.83rem;


      &.enable-hover {
        &:hover {
          @apply bg-pb-grey-200;

          td:first-child {
            border-left-style: solid;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }
          td:last-child {
            border-right-style: solid;
            border-bottom-right-radius: 2px;
            border-top-right-radius: 2px;
          }
        }
      }
    }
  }
}

/**
 * NGPrime Input field
 */
.p-inputtext {
  @apply text-pb-grey-600;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 400;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  background: #fff;
  @apply border-pb-grey-400;
  @apply border;
  border-radius: 2px;
  box-sizing: border-box;
}

.p-inputtext:enabled:hover {
  @apply border-pb-grey-500;
}

.p-inputtext:enabled:active {
  @apply border-pb-blue-700;
}

.p-inputtext:disabled {
  @apply bg-pb-grey-200;
}

.p-inputtext:focus {
  outline: 0 none;
  box-shadow: 0 0 5px 1px;
  @apply shadow-pb-blue-600;
}

.p-input-icon-right {
  position: relative;

  .mat-icon {
    position: absolute;
    right: 8px;
    top: -4px;
  }

  i {
    position: absolute;
    right: 10px !important;
    top: 9px !important;
  }
}

.p-inputtext.ng-touched.ng-invalid {
  @apply border-pb-red-700;
}

p-fieldset {
  @apply block mb-3;
}

.p-fieldset-content {
  @apply flex flex-col;
}

.p-label {
  @apply text-pb-grey-800 text-sm leading-4 mb-2;
}

.p-error {
  @apply flex flex-row gap-1 mb-1;

  .rounded-icon {
    @apply w-3 h-3;
  }

  i {
    @apply rounded-full w-3 h-3 p-[3px] bg-pb-red-700;
    @apply text-white;
  }
}

.p-error-text {
  @apply text-pb-red-700 text-xs leading-4;
}

/**
 * NGPrime Select button
 */
.p-buttonset .p-button:not(:last-child) {
  border-right: 1px solid;
}
.p-selectbutton {
  .p-button {
    background: white;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    @apply border;
    @apply border-pb-grey-400;
    @apply text-pb-grey-700;

    @apply shadow-sm;

    &:not(.p-disabled):not(.p-highlight):hover {
      @apply bg-pb-grey-100;
    }

    &.p-highlight {
      @apply bg-pb-blue-100;
      @apply border;
      @apply border-pb-blue-500;
      @apply text-pb-blue-500;
      @apply font-bold;
    }
  }
}


/**
 * NGPrime Sidebar
 */

 .p-drawer {
  width: auto;
 }

.p-drawer-header {
  @apply w-full pl-16 pr-4 py-4 border-b bg-white border-pb-grey-300 flex flex-row justify-between items-center;
}

.p-drawer-content {
  @apply py-8 px-12 bg-pb-grey-100 text-pb-grey-800;
}

.iam-sidebar-block {
  @apply mb-8 p-6 border border-pb-grey-300 bg-white;
}

.iam-sidebar-block--flex {
  @apply flex flex-row flex-wrap align-top justify-between;
}

.p-drawer .iam-sidebar-block-label {
  @apply leading-none text-[14px] font-normal mt-0 mb-2;
}

.p-drawer .iam-sidebar-block-field {
  @apply leading-none text-[14px] font-medium break-all;
}

.p-drawer-close {
  @apply absolute left-4;
}

.iam-sidebar-block-col {
  @apply w-[calc(50%-1.5rem)];
}

.p-drawer-close svg {
  @apply w-8 h-8 p-2;
}

.iam-sidebar-mask {
  @apply fixed w-full h-full top-0 left-0 bg-black opacity-50 pointer;
}

/**
 * NGPrime Dropdown
 */
.p-select {
  display: flex;
  cursor: pointer;
  position: relative;
  user-select: none;
  @apply border border-pb-grey-400 h-10 text-sm;
}

.p-select.p-disabled,
.p-select.p-disabled .p-inputtext {
  @apply bg-pb-grey-200;
}

.p-select .p-inputtext {
  @apply border-0 h-auto;
}

.p-select-clear-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-select-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  @apply ml-2;
}

.p-select-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
  width: 1%;
  text-overflow: ellipsis;
  cursor: pointer;
}

.iam-sidebar-block .p-select-label {
  @apply py-0;
}

.p-select-label-empty {
  overflow: hidden;
  visibility: hidden;
}

input.p-select-label {
  cursor: default;
}

.p-select-items-wrapper {
  overflow: auto;
}

.p-select-item {
  cursor: pointer;
  font-weight: normal;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  @apply py-1.5 px-4 hover:bg-pb-grey-200 focus:bg-pb-grey-300;
}

.p-select-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
  @apply border border-pb-grey-400 bg-white rounded-sm;
}

.p-select-filter {
  width: 100%;
}

.p-select-filter-container {
  position: relative;
}

.p-select-filter-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-fluid .p-select {
  display: flex;
}

.p-fluid .p-select .p-select-label {
  width: 1%;
}

.p-select-trigger-icon {
  @apply text-pb-blue-700 w-3 h-3;
}

.p-select-label.p-inputtext {
  @apply text-pb-grey-800;
}

.p-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/**
 * NGPrime Calendar
 */

.p-datepicker{
  box-shadow: none !important;
  border: 1px solid !important;
  @apply border-pb-grey-200 !important;
  padding: 0px !important;
  border-radius: 0px !important;

  .p-inputtext{
    border: 0 !important;
  }
  button{
    margin-right: 14px;
  }

  .p-disabled{
    @apply text-pb-grey-300;
  }
}


// Dialog
.p-dialog-mask {
  background: rgba(0, 0, 0, 0.4); /* black background with 30% opacity */
}
.p-dialog {
  border-radius: 4px;
  box-shadow:
    0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: 0 none;
}
.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: white;
  @apply text-pb-blue-700;
  padding: 2rem 2.5rem 1rem 2.5rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 500;
  font-size: 1.25rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(255, 255, 255, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: rgba(255, 255, 255, 0.6);
  border-color: transparent;
  background: rgba(255, 255, 255, 0.04);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: white;
  @apply text-pb-grey-700;
  padding: 0 2.5rem 2.5rem 2.5rem;
}
.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #262626;
  color: rgba(255, 255, 255, 0.87);
  padding: 1rem 1.5rem;
  text-align: right;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
  width: 2rem;
  height: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

// fileUploader
.p-fileupload {
  @apply bg-pb-grey-100;
  @apply border border-pb-grey-300;
  @apply text-pb-grey-600;

  .p-fileupload-header{
    @apply flex-col items-start;
    .p-button-label{
      @apply ml-2;
    }
    @apply p-4;
    @apply border-b border-pb-grey-600;
    .p-element {
      @apply mr-2;
      .fa-upload {
        @apply mr-1;
      }
    }
  }
}



// Message
.p-message {
  @apply border rounded;
}
.p-message .p-message-wrapper {
  padding: 1.25rem 1.5rem;
}
.p-message .p-message-close {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: transparent;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.5);
}
.p-message .p-message-close:focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-message.p-message-info {
  @apply bg-pb-blue-100 text-pb-blue-700 border-pb-blue-300;
}

.p-message.p-message-success {
  @apply bg-pb-green-100 text-pb-green-700 border-pb-green-300;
}

.p-message.p-message-warn {
  @apply bg-pb-yellow-100 text-pb-yellow-700 border-pb-yellow-300;
}

.p-message.p-message-error {
  @apply bg-pb-red-100 text-pb-red-700 border-pb-red-300;
}

.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}
.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.p-message .p-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.p-message .p-message-summary {
  font-weight: 700;
}
.p-message .p-message-detail {
  margin-left: 0.5rem;
}

// Tooltip
.p-tooltip {
  @apply absolute;

  .p-tooltip-text {
    @apply bg-pb-grey-800 text-white p-4 rounded text-xs;
  }
}

// Radio button
.p-radiobutton {

  .p-radiobutton-box {
    border: 1px solid map.get($colors, 'grey-400');
    width: 1rem;
    height: 1rem;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 50%;

    &:hover {
      box-shadow: 0 0 0 32px map.get($colors, 'blue-100');
      background: map.get($colors, 'blue-100');
      clip-path: circle(100%);
    }

    &:active {
      box-shadow: 0 0 0 32px map.get($colors, 'blue-200');
      background: map.get($colors, 'blue-200');
      clip-path: circle(100%);
    }

    &:focus::before {
      content: '';
      position: absolute;
      box-shadow: 0 0 0.25rem 1px map.get($colors, 'blue-500');
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
    }

    &.p-highlight {
      border: 1.5px solid map.get($colors, 'blue-700');
      background: map.get($colors, 'grey-100');
      padding: 3px;

      .p-radiobutton-icon {
        width: 0.5rem;
        height: 0.5rem;
        transition-duration: 0.2s;
        background-color: map.get($colors, 'blue-700');
        border-radius: 50%;
        display: flex;
      }

      &.p-disabled {
        border: 1.5px solid map.get($colors, 'blue-300');

        .p-radiobutton-icon {
          background-color: map.get($colors, 'blue-300');
        }
      }

      &:hover {
        background: map.get($colors, 'blue-100');
      }

      &:active {
        background: map.get($colors, 'blue-200');
      }
    }

    &.p-disabled {
      border: 1px solid map.get($colors, 'grey-400');
      background: map.get($colors, 'grey-300');

    }
  }
}

.p-togglebutton {
  height: initial;
  background: white;
  border-color: map.get($colors, 'grey-400');
  color: map.get($colors, 'grey-800');

  &.p-disabled {
    background: map.get($colors, 'grey-200');
    color: map.get($colors, 'grey-600');
    border-color: map.get($colors, 'grey-400');
    pointer-events: none;
  }

  &:hover:not(.p-disabled) {
    border-color: map.get($colors, 'grey-500');
  }

  &:focus:not(.p-disabled) {
    border-color: map.get($colors, 'grey-500');
  }

  &.p-highlight {
    background: map.get($colors, 'blue-100');
    border-color: map.get($colors, 'blue-500');
    color: map.get($colors, 'blue-700');

    &.p-disabled {
      background: map.get($colors, 'blue-100');
      color: map.get($colors, 'blue-300');
      border-color: map.get($colors, 'blue-200');
    }

    &:hover:not(.p-disabled) {
      border-color: map.get($colors, 'blue-600');
    }

    &:focus:not(.p-disabled) {
      border-color: map.get($colors, 'blue-500');
    }
  }
}


// PrimeNG listbox

/* Style for the PrimeNG Listbox component */
.p-listbox {
  background: #ffffff; /* White background similar to Material */
  border: 1px solid #e0e0e0; /* Light border */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; /* Material font */
}

.p-listbox .p-listbox-list {
  padding: 0; /* No padding for the list itself */
  margin: 0; /* No margin */
}

.p-listbox .p-checkbox {
  margin-right: 12px;
}

.p-listbox .p-listbox-item {
  padding: 12px 32px; /* Material-style spacing */
  height: 48px;
  border-bottom: 1px solid #e0e0e0; /* Divider between items */
  color: #424242; /* Default text color */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.2s, box-shadow 0.2s; /* Smooth hover effect */
  font-size: 16px;
  line-height: 20px;
}
  
.p-listbox .p-listbox-item:last-child {
  border-bottom: none; /* Remove border for the last item */
}

.p-listbox .p-listbox-item:hover {
  background: rgba(0, 0, 0, 0.04); /* Light hover background */
}

.p-listbox .p-listbox-item.p-highlight {
  background: #e3f2fd; /* Highlighted item color (Material blue 100) */
  color: #0d47a1; /* Material blue 900 for text */
}

/* Focus and selected states */
.p-listbox:focus-within {
  border-color: #1976d2; /* Material blue 700 on focus */
  box-shadow: 0px 0px 0px 2px rgba(25, 118, 210, 0.2); /* Focus outline */
}

.p-listbox .p-listbox-item:focus {
  outline: none; /* Remove default outline */
  background: rgba(25, 118, 210, 0.1); /* Focused item background */
}

.radiobutton-wrapper:has(input[type="radio"]:disabled) label{
  @apply text-pb-grey-500;
}
